import packageJson from '../../../package.json';
import { getOptionalStringEnv, getRequiredStringEnv } from '../../utils/safeEnvParsing';

export const isDevPreviewDomain =
  typeof window !== 'undefined' ? window?.location?.origin?.includes('preview.speechify.dev') : getOptionalStringEnv('VERCEL_ENV') === 'preview';

export const isLocalhost = typeof window !== 'undefined' ? window?.location?.hostname === 'localhost' : getOptionalStringEnv('VERCEL_ENV') === 'development';

export const PSPDFKIT_KEY = isLocalhost
  ? getRequiredStringEnv('NEXT_PUBLIC_PSPKIT_LOCALHOST')
  : isDevPreviewDomain
    ? getOptionalStringEnv('NEXT_PUBLIC_PSPKIT_DEV_PREVIEW')
    : getOptionalStringEnv('NEXT_PUBLIC_PSPKIT');

export const PSPDFKIT_VERSION = packageJson.dependencies['pspdfkit'];

export const CDN_PSPDFKIT_BASE_URL = `https://cdn.speechify.com/web/pspdfkit/${PSPDFKIT_VERSION}/`;
