import dynamic from 'next/dynamic';
import React, { Fragment } from 'react';

import Head from 'components/elements/Head';
import Meta from 'components/elements/Meta';
import { useTranslation } from 'hooks/useTypedTranslation';

const LoginForm = dynamic(() => import('components/account/LoginForm'), {
  ssr: false
});

const LoginPage = () => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      <Head title={t('Sign In')} />
      <Meta />

      <div className="flex min-h-screen items-center justify-center bg-pageBackground">
        <LoginForm />
      </div>
    </Fragment>
  );
};

export default LoginPage;
